import get from 'lodash.get';
import {decorate, observable, computed, action} from 'mobx';

import {pollOffers} from "../../utils/offer-utils";


export class OffersStore {
    apiStore = null;
    partnerStore = null;
    dealershipStore = null;
    rideOctaneStore = null;
    historyStore = null;
    applicationUuid = null;
    applicantUuid = null;
    hasSubmittedSSN = null;
    isApplicantCMPFlow = false;

    // observables
    hasActiveLoans = false;
    offers = null;
    hasLoadedOffers = false;
    hasSentOffersEmail = false;
    offersEndpoint = null;
    hasSubmittedRCData = false;
    serverErrorMessage = "";

    /**
     * Helper to log errors
     * @param {string} errorMessage
     */
    _logError(errorMessage) {
        if (this.partnerStore.partnerIdentifier) {
            console.error(
                "OffersStore:",
                errorMessage,
                "for partner",
                this.partnerStore.partnerIdentifier,
            );
        } else {
            console.error("OffersStore:", errorMessage);
        }

        this.serverErrorMessage = errorMessage
            + (this.partnerStore.partnerIdentifier ? ` for partner ${this.partnerStore.partnerIdentifier}` : "");
    }

    get showServerErrorView() {
        if (!this.applicationUuid) {
            // if no applicationUuid to load offers
            this._logError("No application uuid");
            return true;
        }

        if (this.isApplicantCMPFlow && !this.applicantUuid) {
            this._logError("No applicant uuid");
            return true;
        }

        if (!this.hasLoadedOffers) {
            // loading offers
            return false;
        }

        if (!this.offers) {
            this._logError(`No offers for application ${this.applicationUuid}`);
            return true;
        }

        if (this.applicationStatus === "error") {
            this._logError(`Application status is error for application ${this.applicationUuid}`);
            return true;
        }

        if (
            !!(
                this.partnerStore.partner &&
                this.partnerStore.partner.enable_dealer_decision
            ) &&
            this.applicationResult === "declined" &&
            !this.applicationDealerResult
        ) {
            this._logError(`Missing dealer decision result for application ${this.applicationUuid}`);
            return true;
        }

        return false;
    }

    get showLoadingOffersView() {
        if (this.showServerErrorView) {
            return false;
        }
        return !this.hasLoadedOffers;
    }

    get showCreditProfileLockedView() {
        return this.applicationResult === "credit_profile_locked";
    }

    get showNoCreditReportFoundView() {
        return this.applicationResult === "no_credit_report_found";
    }

    get showApplicationDeclinedView() {
        if (this.applicationResult !== "declined") {
            return false;
        }

        if (this.partnerStore.partner && this.partnerStore.partner.enable_dealer_decision) {
            return this.applicationDealerResult === "declined";
        }

        return true;
    }

    get showApplicationDealerApprovedView() {
        if (this.applicationResult !== "declined") {
            return false;
        }

        if (this.partnerStore.partner && this.partnerStore.partner.enable_dealer_decision) {
            return this.applicationDealerResult === "approved";
        }

        return false;
    }

    get showApplicationApprovedView() {
        return (this.applicationResult === "approved") && !this.hasSentOffersEmail;
    }

    get showEmailSentView() {
        return (this.applicationResult === "approved") && this.hasSentOffersEmail;
    }

    get showTransparentOffers() {
        return (this.partnerStore.partner && this.partnerStore.partner.show_transparent_offers) ?? true;
    }

    get showReturnCustomerPage() {
        return this.applicationResult === "return_customer";
    }

    /**
     * Returns the final status of decisioning after offers have loaded (e.g. done, error)
     */
    get applicationStatus() {
        if (!this.offers) {
            return null;
        }

        if (get(this.offers, 'loan.status') === 'done') {
            return "done";
        }
        return "error";
    }

    /**
     * Returns the Octane Result from decisioning
     * @see ride-octane-api/ride_octane_api/rideoctane/enums/applications.py::ApplicationStatus
     */
    get applicationResult() {
        if (!this.offers || this.offers.loan.status === "error") {
            return null;
        }
        const loanResult = get(this.offers, 'loan.result');
        const hasLoanOffers = get(this.offers, 'loan.offers.length') > 0;
        if (loanResult === 'approved' && hasLoanOffers) {
            return "approved";
        }
        if (loanResult === 'credit_profile_locked') {
            return "credit_profile_locked";
        }
        if (loanResult === 'no_credit_report_found') {
            return "no_credit_report_found";
        }
        if (loanResult === 'manual_review' && this.hasActiveLoans) {
            return "return_customer";
        }
        return "declined";
    }

    /**
     * Returns the dealer decision result if the application was Octane declined
     */
    get applicationDealerResult() {
        if (this.applicationResult !== 'declined') {
            return null;
        }

        const dealerResult = get(this.offers, 'dealer_decision.dealer_result');
        if (!['approved', 'declined'].includes(dealerResult)) {
            return null;
        }
        return dealerResult;
    }

    get completeMyPaperworkUrl() {
        let paperworkUrl = `/paperwork/${this.applicationUuid}`;

        if(this.isApplicantCMPFlow){
            paperworkUrl += `/${this.applicantUuid}`;
        }
        return paperworkUrl + `/additional-info/`;
    }

    // helper used in contactInfo to split multi line addresses for formatting
    _formatContactAddress = address => address ? address.split('\n') : void 0;

    get contactInfo() {
        if (this.dealershipStore.dealership && this.dealershipStore.dealership.contact_name) {
            return {
                contact_name: this.dealershipStore.dealership.contact_name,
                contact_address: this._formatContactAddress(
                    this.dealershipStore.dealership.contact_address
                ),
                contact_phone_number: this.dealershipStore.dealership.contact_phone_number,
                contact_email_address: this.dealershipStore.dealership.contact_email_address,
            };
        } else if (this.partnerStore.partner && this.partnerStore.partner.contact_name) {
            return {
                contact_name: this.partnerStore.partner.contact_name,
                contact_address: this._formatContactAddress(
                    this.partnerStore.partner.contact_address
                ),
                contact_phone_number: this.partnerStore.partner.contact_phone_number,
                contact_email_address: this.partnerStore.partner.contact_email_address,
            };
        }
        return null;
    }

    /**
     * Returns trade in url from the dealership if available, otherwise it returns from the partner if available.
     */
    get tradeInUrl() {
        const dealership = this.dealershipStore.dealership;
        const partner = this.partnerStore.partner;

        if (!!dealership && !!dealership.trade_in_url) {
            return dealership.trade_in_url;
        } else if (!!partner && !!partner.trade_in_url) {
            return partner.trade_in_url;
        }
        return null;
    }

    /**
     * Returns finance url from the dealership if available, otherwise it returns from the partner if available.
     */
    get financeUrl() {
        const dealership = this.dealershipStore.dealership;
        const partner = this.partnerStore.partner;

        if (!!dealership && !!dealership.finance_url) {
            return dealership.finance_url;
        } else if (!!partner && !!partner.partner_finance_url) {
            return partner.partner_finance_url;
        }
        return null;
    }

    constructor({
                    apiStore,
                    partnerStore,
                    dealershipStore,
                    rideOctaneStore,
                    historyStore,
                    applicationUuid,
                    applicantUuid,
                    hasSubmittedSSN,
                    isApplicantCMPFlow,
                    offersEndpoint,
                    hasSubmittedRCData,
                }) {
        this.apiStore = apiStore;
        this.partnerStore = partnerStore;
        this.dealershipStore = dealershipStore;
        this.rideOctaneStore = rideOctaneStore;
        this.historyStore = historyStore;
        this.applicationUuid = applicationUuid;
        this.applicantUuid = applicantUuid;
        this.hasSubmittedSSN = hasSubmittedSSN;
        this.isApplicantCMPFlow = isApplicantCMPFlow;
        this.offersEndpoint = offersEndpoint ? offersEndpoint : this.getOffersEndpoint();
        this.hasSubmittedRCData = hasSubmittedRCData;
    }

    getOffersEndpoint = () => {
        // application status endpoint
        let endpoint = `/applications/${this.applicationUuid}/status`;
        const locationSearch = typeof window !== 'undefined' ? document.location.search : undefined
        const searchParams = new URLSearchParams(locationSearch);
        if (this.partnerStore.partnerIdentifier) {
            endpoint = `/partners/${this.partnerStore.partnerIdentifier}` + endpoint;

            if (this.partnerStore.isRV) {
                const partnerTokenId = this.partnerStore.partnerTokenId;
                if (partnerTokenId != null) {
                    searchParams.set('partner_data', JSON.stringify({'token_id': partnerTokenId}));
                } else {
                    console.error(`RV Application ${this.applicationUuid} has a null partnerTokenId`);
                }
            }
        }

        // add search params if they exist
        const strSearchParams = searchParams.toString();
        if (strSearchParams) {
            endpoint += `?${strSearchParams}`;
        }
        return endpoint;
    }

    loadOffers() {
        // poll the status endpoint until decisioning is done
        return pollOffers(this.offersEndpoint, this.apiStore, this.hasSubmittedRCData)
            .then(response => {
                if (!response || response.error) {
                    return this.updateOffers({offers: null});
                }
                if (!!response.active_loans && response.active_loans.length > 0) {
                    this.hasActiveLoans = true;
                }
                this.updateOffers({
                    offers: {
                        loan: response.loan,
                        dealer_decision: response.dealer_decision,
                    },
                });
                this.postDecisionStatusToParentWindow();
                return this.sendPostMessageToParentWindow();
            });
    }

    updateOffers({offers}) {
        this.offers = offers;
        this.hasLoadedOffers = true;
        return this;
    }

    updateHasSentOffersEmail = () => {
        this.hasSentOffersEmail = true;
        return this;
    };

    postDecisionStatusToParentWindow = () => {
        let vehicleData = null;

        if (this.partnerStore.partnerVehicleMatch) {
            vehicleData = {
                'make': this.partnerStore.partnerVehicleMatch.make,
                'model': this.partnerStore.partnerVehicleMatch.model,
                'year': this.partnerStore.partnerVehicleMatch.year,
                'price': this.partnerStore.partnerVehicleMatch.price,
                'condition': this.partnerStore.partnerVehicleMatch.condition,
            }
        } else {
            vehicleData = this.partnerStore.rawVehicle;
        }
        const decisionVehicleMessage = {
            'decisionStatus': this.applicationResult,
            'vehicleData': vehicleData
        }
        window.parent.postMessage(decisionVehicleMessage, '*');
    }

    /**
     * If this is a Reveo partner experience, we will send these fields via postMessage:
     * 1. decisionStatus
     * 2. paperworkUrl
     *
     * After prequal, if we render the ssn-required view, we hold off on sending the postMessage until user has submitted the SSN and decision has been re-run.
     */
    sendPostMessageToParentWindow = () => {
        const targetUrls = [];
        let decisionStatus = null;
        let paperworkUrl = null;
        if (this.partnerStore.isEmbeddedPartner) {
            if (this.rideOctaneStore.settings.ENVIRONMENT_KEY !== 'production') {
                targetUrls.push('*');
            } else {
                targetUrls.push(this.partnerStore.partner.partner_url);
                if (this.partnerStore.partnerOriginUrl) {
                    targetUrls.push(new URL(this.partnerStore.partnerOriginUrl).origin);
                }
            }
            switch (this.applicationResult) {
                case 'approved':
                    decisionStatus = 'approved';
                    paperworkUrl = this.rideOctaneStore.settings.BASE_UX_URL +
                        '/paperwork/' + this.applicationUuid +
                        (this.isApplicantCMPFlow ? ('/' + this.applicantUuid) : '') +
                        '/?' +
                        'partner=' + this.partnerStore.partnerIdentifier + '&' +
                        'dealership=' + this.dealershipStore.dealershipId + '&' +
                        'appTheme=' + this.historyStore.queryParams.appTheme;
                    break;

                case 'declined':
                    decisionStatus = 'declined';
                    break;

                case 'no_credit_report_found':
                    if (this.hasSubmittedSSN) {
                        decisionStatus = 'declined';
                    }
                    break;

                case 'credit_profile_locked': //Reveo needs a specific decisionStatus to know to skip their full credit app
                    decisionStatus = 'decline_locked';
                    break;

                default:
                    break;
            }
        }

        const postMessageBody = {
            'decisionStatus': decisionStatus,
            'paperworkUrl': paperworkUrl,
            'currentPage': 'offers',
        };
        if (decisionStatus) {
            for (const targetUrl of targetUrls) {
                window.parent.postMessage(postMessageBody, targetUrl);
            }
        }

        return this;
    }
}

decorate(OffersStore, {
    offers: observable,
    hasLoadedOffers: observable,
    hasSentOffersEmail: observable,
    offersEndpoint: observable,
    serverErrorMessage: observable,

    showServerErrorView: computed,
    showLoadingOffersView: computed,
    showCreditProfileLockedView: computed,
    showNoCreditReportFoundView: computed,
    showApplicationDeclinedView: computed,
    showApplicationApprovedView: computed,
    showEmailSentView: computed,
    showApplicationDealerApprovedView: computed,

    showTransparentOffers: computed,

    applicationStatus: computed,
    applicationResult: computed,
    applicationDealerResult: computed,

    contactInfo: computed,
    tradeInUrl: computed,
    financeUrl: computed,

    updateOffers: action,
    updateHasSentOffersEmail: action,
});
