import React from 'react';
import PropTypes from 'prop-types';
import {reaction} from 'mobx';
import {inject, observer} from 'mobx-react';
import {Redirect} from "react-router-dom";
import {Container} from 'react-bootstrap';

import {ServerError} from "../../components/interstitials";
import {
    OffersLoading,
    NoCreditReportFound,
    EmailReminderSent,
} from "./offers-interstitials";
import {OffersLayout} from "../../components/offers/offers-layout";
import {OffersDeclined} from "../../components/offers/offers-declined";
import {ReveoCTAFooter} from "../../components/reveo-cta-footer";
import {isInIFrame} from "../../utils/iframe-utils";

import {OffersStore} from "./offers-store";
import copy from "./copy.json";
import {pushApplicationFlow, pushOfferApproval, pushOfferSubmit} from "../../utils/gtm-utils";


export const Offers = inject('apiStore', 'uxCopyStore', 'partnerStore', 'dealershipStore', 'rideOctaneStore', 'historyStore')(
    observer(
        class _Offers extends React.Component {
            static propTypes = {
                applicationUuid: PropTypes.string.isRequired,
                applicantUuid: PropTypes.string,
                hasCompletedAboutYouForm: PropTypes.bool.isRequired,
                hasSubmittedSSN: PropTypes.bool.isRequired,
                aboutYouPrequalUrl: PropTypes.string.isRequired,
                returnCustomerUrl: PropTypes.func,
                ssnRequiredUrl: PropTypes.string,
                isApplicantCMPFlow: PropTypes.bool.isRequired,
                accessoriesAmount: PropTypes.number,
                vehicleConfiguration: PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    msrp: PropTypes.number.isRequired,
                    color: PropTypes.object.isRequired,
                    default_image: PropTypes.string.isRequired,
                }),
                vssConfiguration: PropTypes.object,
                offersEndpoint: PropTypes.string,
                hasSubmittedRCData: PropTypes.bool,
            };

            disposeScrollOnLoaded = null;
            store = new OffersStore(this.props);

            componentDidMount() {
                window.scrollTo(0, 0);

                if (!this.store.showServerErrorView) {
                    this.disposeScrollOnLoaded = reaction(
                        () => this.store.hasLoadedOffers,
                        () => {
                            window.scrollTo(0, 0);
                            this.disposeScrollOnLoaded();
                            this.disposeScrollOnLoaded = null;
                        }
                    );

                    this.store.loadOffers();
                }
                pushOfferSubmit('submitted_about_you', this.props.applicationUuid);
            }
            
            componentWillUnmount() {
                if (this.disposeScrollOnLoaded) {
                    this.disposeScrollOnLoaded();
                }
            }

            render() {
                const isRVExperienceInIframe = this.props.partnerStore.isRV && isInIFrame();
                
                if (!this.props.hasCompletedAboutYouForm) {
                    return (
                        <Redirect to={this.props.aboutYouPrequalUrl}/>
                    );
                }

                if (this.store.showReturnCustomerPage) {
                    return (
                        <Redirect to={this.props.returnCustomerUrl}/>
                    );
                }

                if (this.store.showServerErrorView) {
                    return (
                        <Container>
                            <div data-oid="offers-error-page">
                                {pushApplicationFlow(this.props.applicationUuid, 'offer_error')}
                                <ServerError 
                                    origin="offers"
                                    errorMessage={this.store.serverErrorMessage}
                                />
                            </div>
                        </Container>
                    );
                }

                if (this.store.showLoadingOffersView) {
                    return (
                        <Container>
                            <div data-oid="offers-loading-page">
                                <OffersLoading
                                    showTransparentOffers={this.store.showTransparentOffers}
                                />
                            </div>
                        </Container>
                    );
                }

                if (this.store.showCreditProfileLockedView) {
                    return (
                        <Container>
                            <div data-oid="offers-credit-profile-locked-page">
                                <NoCreditReportFound />
                            </div>
                        </Container>
                    );
                }

                if (this.store.showNoCreditReportFoundView) {
                    if (!this.props.hasSubmittedSSN) {
                        return (
                            <Redirect to={this.props.ssnRequiredUrl}/>
                        );
                    }
                    return (
                        <Container>
                            <div data-oid="offers-no-credit-report-found-page">
                                <NoCreditReportFound />
                            </div>
                        </Container>
                    );
                }

                if (this.store.showEmailSentView) {
                    return (
                        <Container>
                            <div data-oid="offers-email-sent-page">
                                <EmailReminderSent />
                            </div>
                        </Container>
                    );
                }

                if (this.store.showApplicationDeclinedView) {
                    // Offers Declined (either regular decline or dealer decline)
                    let dataOid = "offers-declined-page";
                    if (this.store.applicationDealerResult) {
                        dataOid = "offers-dealer-declined-page";
                    }

                    return (
                        <Container>
                            <div data-oid={dataOid}>
                                {pushApplicationFlow(this.props.applicationUuid, 'offer_declined')}
                                <OffersDeclined
                                    showVehicleHeaderBlock={!this.props.partnerStore.isReveo}
                                    uxCopyStore={this.props.uxCopyStore}
                                    accessoriesAmount={this.props.accessoriesAmount}
                                    vehicleConfiguration={this.props.vehicleConfiguration}
                                    /* For Embedded Partners (Reveo and CW), we don't show dealer contact info */
                                    contactInfo={!this.props.partnerStore.isEmbeddedPartner && this.store.contactInfo}
                                    financeUrl={this.store.financeUrl}
                                    isStickyCTA={!isRVExperienceInIframe}
                                    showBrandingForCampingWorld={this.props.partnerStore.isRV}
                                />
                            </div>
                        </Container>
                    );
                }

                if (this.store.showApplicationDealerApprovedView) {
                    // Octane Declined / Dealer Approved
                    return (
                        <Container>
                            <div data-oid="offers-dealer-approved-page">
                                <OffersLayout
                                    showVehicleHeaderBlock
                                    accessoriesAmount={this.props.accessoriesAmount}
                                    vehicleConfiguration={this.props.vehicleConfiguration}
                                    vssConfiguration={this.props.vssConfiguration}
                                    partnerRawVehicleData={this.props.partnerStore.rawVehicle}
                                    showDecisionBlock
                                    decisionBlock={copy.dealer_approved.decision_block}

                                    showContactBlock
                                    contactInfo={this.store.contactInfo}
                                    contactBlock={copy.dealer_approved.contact_block}

                                    showAdditionalBlock={!!this.store.tradeInUrl}
                                    additionalBlock={copy.dealer_approved.additional_block}

                                    showDisclosureBlock
                                    disclosureBlock={copy.dealer_approved.disclosure_block}

                                    showCTAsBlock={!!this.store.tradeInUrl}
                                    ctasBlock={{
                                        isSticky: !isRVExperienceInIframe,
                                        primary: {
                                            openLinkInNewTab: true,
                                            href: this.store.tradeInUrl,
                                            ...copy.dealer_approved.primary_cta,
                                        },
                                    }}
                                />
                            </div>
                        </Container>
                    );
                }

                const warningNoteBlockBody = this.props.uxCopyStore.getOffersCopy(
                    'approved.warning_note_block.body',
                );

                // Octane Approved Low Transparency for when show_transparent_offers is false in partnerStore
                if (!this.store.showTransparentOffers) {
                    const isReveoExperienceInIframe = this.props.partnerStore.isReveo && isInIFrame();
                    
                    // Insert the CTA label into the paperwork body copy
                    const paperworkBlockDefaultBody =
                        copy.approved_low_transparency.paperwork_block.body.join(
                            ` "${copy.approved_low_transparency.primary_cta.label}" `,
                        );

                    let paperworkBlockBody;

                    if (this.store.partnerStore.isRV) {
                        paperworkBlockBody = "Please contact the nearest Camping World sales associate.";
                    } else {
                        paperworkBlockBody = this.props.uxCopyStore.getOffersCopy(
                            "approved.paperwork_block.body",
                            paperworkBlockDefaultBody,
                        );
                    }
                    
                    return (
                        <Container>
                            <div data-oid="offers-approved-page">
                                {pushOfferApproval(this.props.applicationUuid)}
                                <OffersLayout
                                    /* For Reveo, we don't show the vehicle for Low Transparency approval */
                                    showVehicleHeaderBlock={!this.props.partnerStore.isReveo}
                                    accessoriesAmount={this.props.accessoriesAmount}
                                    vehicleConfiguration={this.props.vehicleConfiguration}
                                    vssConfiguration={this.props.vssConfiguration}
                                    partnerRawVehicleData={this.props.partnerStore.rawVehicle}

                                    showDecisionBlock
                                    decisionBlock={copy.approved_low_transparency.decision_block}

                                    showWarningNoteBlock
                                    warningNoteBlock={{body: warningNoteBlockBody}}

                                    showPaperworkBlock
                                    paperworkBlock={{body: paperworkBlockBody}}

                                    /* For Reveo, we don't show dealer contact info */
                                    showContactBlock={!this.props.partnerStore.isReveo}
                                    contactInfo={this.store.contactInfo}
                                    contactBlock={copy.approved_low_transparency.contact_block}

                                    showDisclosureBlock
                                    disclosureBlock={copy.approved_low_transparency.disclosure_block}

                                    /* Hide default sticky CTAs for Reveo partner experience in an iFrame */
                                    showCTAsBlock={!isReveoExperienceInIframe && !this.store.partnerStore.isRV}
                                    ctasBlock={{
                                        primary: {
                                            ...copy.approved_low_transparency.primary_cta,
                                            href: this.store.completeMyPaperworkUrl,
                                        }
                                    }}
                                />
                                {/* Render ReveoCTAFooter for Reveo partner experience in an iFrame */}
                                {isReveoExperienceInIframe && (
                                    <ReveoCTAFooter
                                        href={this.store.completeMyPaperworkUrl}
                                        data-oid={copy.approved_low_transparency.primary_cta.oid}
                                    />
                                )}
                            </div>
                        </Container>
                    );
                }

                const ctasBlock = {
                    isSticky: !isRVExperienceInIframe,
                    primary: { 
                        ...copy.approved.primary_cta,
                        href: this.store.completeMyPaperworkUrl,
                    }
                };

                if(!isRVExperienceInIframe){
                    ctasBlock['secondary'] = {
                        ...copy.approved.secondary_cta,
                        onClick: this.store.updateHasSentOffersEmail,
                    }
                }

                // Marketplace / OEM OWI / Media Group - Octane Approved
                const { uxCopyStore } = this.props;
                const decisionBlockHeadline = uxCopyStore.getOffersCopy(
                    'approved.decision_block.headline',
                    copy.approved.decision_block.headline
                );
                const decisionBlockBody = uxCopyStore.getOffersCopy(
                    'approved.decision_block.body',
                    copy.approved.decision_block.body
                );
                const disclosureBlockBody = uxCopyStore.getOffersCopy(
                    'approved.disclosure_block.body',
                    copy.approved.disclosure_block.body
                );

                return (
                    <Container>
                        <div data-oid="offers-approved-page">
                            {pushOfferApproval(this.props.applicationUuid)}
                            <OffersLayout
                                showVehicleHeaderBlock
                                accessoriesAmount={this.props.accessoriesAmount}
                                vehicleConfiguration={this.props.vehicleConfiguration}
                                vssConfiguration={this.props.vssConfiguration}
                                partnerRawVehicleData={this.props.partnerStore.rawVehicle}

                                showDecisionBlock
                                decisionBlock={{
                                    ...copy.approved.decision_block,
                                    headline: decisionBlockHeadline,
                                    body: decisionBlockBody, 
                                }}
                                showWarningNoteBlock
                                warningNoteBlock={{body: warningNoteBlockBody}}

                                showTransparentOffersBlock
                                offers={this.store.offers.loan.offers}

                                showDisclosureBlock
                                disclosureBlock={{
                                    ...copy.approved.disclosure_block,
                                    body: disclosureBlockBody,
                                }}

                                showCTAsBlock
                                ctasBlock={ctasBlock}
                                showBrandingForCampingWorld={this.props.partnerStore.isRV}
                            />
                        </div>
                    </Container>
                );
            }
        }
    )
);
