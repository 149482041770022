import React from 'react';
import {inject, observer} from 'mobx-react';
import {Container} from 'react-bootstrap';

import PropTypes from 'prop-types';
import {ReturnCustomerStore} from "./return-customer-store";

import {Form} from 'react-bootstrap';

import classnames from 'classnames';
import styles from "./return-customer.module.scss";

import {CTAFooter} from "../../components/cta-footer";
export const ReturnCustomer = inject('apiStore', 'historyStore')(
    observer(
        class _ReturnCustomer extends React.Component {
            static propTypes = {
                applicationUuid: PropTypes.string,
                onSubmitRCData: PropTypes.func,
            };

            store = new ReturnCustomerStore(this.props);

            handleTradeInChange(e) {
                this.store.updateTradeInIntent(e.target.id === "trade-in-yes");
            }
            handleSelectLoan(e) {
                this.store.updateSelectedLoans(e.target.id, e.target.checked)
            }
            handleSubmit(e) {
                this.props.onSubmitRCData(this.store.isTradeIn, this.store.selectedLoans);
            }
            componentDidMount() {
                window.scrollTo(0, 0);
                this.store.loadActiveLoans();
            }
            render() {
                return (
                    <Container className={styles.container}>
                        <h5 className={classnames("text-center spark-text-primary font-weight-bold mb-3", styles.existingLoan)}>You have an existing loan with Octane</h5>
                        <Form>
                            <Form.Group>
                                <div className={styles.inputTitle}>Do you have a trade-in which is financed by Octane?</div>
                                <Form.Check 
                                    className={styles.radioLabel}
                                    inline
                                    label="Yes"
                                    name="isTradeIn"
                                    type="radio"
                                    id="trade-in-yes"
                                    onChange={this.handleTradeInChange.bind(this)}
                                    checked={this.store.isTradeIn}>
                                </Form.Check>
                                <Form.Check 
                                    className={styles.radioLabel}
                                    inline
                                    label="No"
                                    name="isTradeIn"
                                    type="radio"
                                    id="trade-in-no"
                                    onChange={this.handleTradeInChange.bind(this)}
                                    checked={!this.store.isTradeIn}>
                                </Form.Check>
                            </Form.Group>
                            {
                                this.store.isTradeIn &&
                                <Form.Group>
                                    <div className={classnames(styles.inputTitle, styles.activeLoansTitle)}>Which vehicle(s) are you trading-in?</div>
                                    {this.store.activeLoans.map((loan) => (
                                        <Form.Check type="checkbox" id={loan.loan_id + "-loan"} className={styles.checkboxContainer}>
                                            <Form.Check.Input
                                                id={loan.loan_id}
                                                type={"checkbox"}
                                                onChange={this.handleSelectLoan.bind(this)}
                                                className={classnames("border", styles.checkbox)}
                                            />
                                            <Form.Check.Label className="ml-2 form-label">
                                                {loan.collaterals.map((collateral, index) => (
                                                    <div id={loan.loan_id + "-collateral-" + index}>{collateral}</div>
                                                ))}
                                            </Form.Check.Label>
                                        </Form.Check>
                                    ))}
                                </Form.Group>
                            }
                        </Form>
                        <CTAFooter
                            isSticky
                            primary={{
                                oid:'cta-rca-data',
                                isDisabled: !this.store.canContinue,
                                label: "Continue",
                                onClick: this.handleSubmit.bind(this)
                            }}
                        />
                    </Container>
                );
            }
        }
    )
);
