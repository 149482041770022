import {decorate, observable, computed, action} from 'mobx';


export class ReturnCustomerStore {
    // observables
    activeLoans = [];
    selectedLoans = [];
    isTradeIn = false;

    get canContinue() {
        return !this.isTradeIn || this.selectedLoans.length > 0;
    }

    constructor({
        apiStore,
        applicationUuid,
    }) {
        this.isTradeIn = false;
        this.selectedLoans = [];
        this.apiStore = apiStore;
        this.applicationUuid = applicationUuid;
    }

    updateTradeInIntent(isTradeInIntent) {
        this.isTradeIn = isTradeInIntent;
        if (!this.isTradeIn) {
            this.selectedLoans = [];
        }
    }
    updateSelectedLoans(loanId, checked) {
        if (checked) {
            this.selectedLoans.push(loanId);
        } else {
            this.selectedLoans = this.selectedLoans.filter(loan => (loan !== loanId));
        }
    }
    loadActiveLoans() {
        let endpoint = `/api/v2/applications/${this.applicationUuid}`;
        return this.apiStore.fetch(endpoint)
        .then(({status, response}) => {
            if (status !== 200 || !response) {
                console.error('Failed to load active loans', {status, response});
                return null;
            }
            this.activeLoans = response.active_loans
            return response;
        })
        .catch(error => {
            console.error('Failed to load active loans', error);
            return null;
        });
    }
}

decorate(ReturnCustomerStore, {
    activeLoans: observable,
    selectedLoans: observable,
    isTradeIn: observable,
    canContinue: computed,
    updateTradeInIntent: action,
    updateSelectedLoans: action,
    loadActiveLoans: action
});
