export const MARINE_VEHICLE_TYPE = {
    MARINE_POWERBOAT: 'marine power boat',
    MARINE_PONTOON: 'marine pontoon',
};

export const LIST_MARINE_VEHICLE_TYPES = [
    MARINE_VEHICLE_TYPE.MARINE_POWERBOAT,
    MARINE_VEHICLE_TYPE.MARINE_PONTOON,
];

export const LOS_BOAT_TYPES = {
    PONTOON: "Pontoon",
    POWERBOAT: "Powerboat",
};

export const VEHICLE_TYPE_TO_LOS_BOAT_TYPE = {
    [MARINE_VEHICLE_TYPE.MARINE_POWERBOAT]: LOS_BOAT_TYPES.POWERBOAT,
    [MARINE_VEHICLE_TYPE.MARINE_PONTOON]: LOS_BOAT_TYPES.PONTOON,
};
