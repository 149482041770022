import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Spinner } from '@octane/spark';

import { CTAFooter } from '../../components/cta-footer';
import copy from './copy.json';
import styles from './dealer-selector.module.scss';

import {DisclosureBlock} from "../../components/disclosure-block";
import { MilesSelector } from './fields/milesSelector';
import { ZipCodeSearch } from './fields/zipCodeSearch';
import { DealerList } from './list/dealerList';

export const DealerSelector = ({
    isFooterSticky,
    zipCodeUser,
    onSubmitDealership,
    store,
}) => {
    const {
        miles,
        zipCode,
        dealerSelected,
        dealersOptions,
        canContinue,
        isLoading,
    } = store;

    const linkDealerToApplication = () => {
        onSubmitDealership(dealerSelected);
    };

    const handleMilesChange = (miles) => {
        store.updateSearchValues({ miles });
    };

    const handleZipCodeChange = (zipCode) => {
        store.updateSearchValues({ zipCode });
    };

    const handleDisclosureConsent = (checked) => {
        store.updateDisclosureConsentChecked(checked);
    };

    const handleLoadDealers = () => {
        store.searchDealers();
    };

    // load zipCode set for user and search dealers if zipCode is valid
    useEffect(() => {
        store.updateSearchValues({ zipCode: zipCodeUser });
        if (store.isZipCodeValid) store.searchDealers();
    }, [store, zipCodeUser]);

    const handleSubmit = (e) => {
        // prevent form submit
        e.preventDefault();

        // if zipCode valid, search dealers
        if (store.isZipCodeValid) store.searchDealers();
    };

    return (
        <Container className={styles.wrapper}>
            <form
                id="dealer-selector-form"
                name="dealer-selector-form"
                onSubmit={handleSubmit}
            >
                <div className={styles.container}>
                    <h1 className={styles.title}>{copy.header.line1}</h1>
                    <span className={styles.subtitle}>
                        {copy.header.subtitle}
                    </span>

                    <div className={styles.search}>
                        <span className={styles.searchTitle}>
                            {copy.fields.label}
                        </span>
                        <div className={styles.groupInputs}>
                            <MilesSelector
                                value={miles}
                                onChange={handleMilesChange}
                            />

                            <ZipCodeSearch
                                value={zipCode}
                                onChange={handleZipCodeChange}
                                onSearch={handleLoadDealers}
                                isZipCodeValid={store.isZipCodeValid}
                                isLoading={isLoading}
                            />
                        </div>
                    </div>

                    {/* Loading State */}
                    {isLoading && (
                        <div id="loading" className={styles.loading}>
                            <Spinner size={40} background="white" speed={0.4} />
                        </div>
                    )}

                    {/* Empty State */}
                    {!isLoading &&
                        dealersOptions &&
                        dealersOptions.length === 0 && (
                            <div className={styles.results}>
                                <span className={styles.resultsTitle}>
                                    {copy.empty.title}
                                </span>
                                <span className={styles.resultsEmpty}>
                                    {copy.empty.label}
                                </span>
                            </div>
                        )}

                    {!isLoading &&
                        dealersOptions &&
                        dealersOptions.length > 0 && (
                            <DealerList
                                dealerSelected={dealerSelected}
                                options={dealersOptions}
                                onSelectDealer={(dealer) =>
                                    store.updateDealerSelected(dealer)
                                }
                            />
                        )}

                    <div className={styles.expander} />
                </div>
                <DisclosureBlock
                    id="consent"
                    showCheckBox
                    showRuleLine
                    disclosureText={store.disclosure}
                    onChange={handleDisclosureConsent}
                    isChecked={store.disclosureConsentChecked}
                />
            </form>
            <CTAFooter
                isSticky={!isFooterSticky}
                primary={{
                    oid: 'cta-dealer-selector',
                    isDisabled: !canContinue,
                    label: copy.action.continue,
                    onClick: () => {
                        linkDealerToApplication();
                    },
                }}
            />
        </Container>
    );
};
