import React from 'react'

import { Icon, ToolTip, ThemeProvider, theme } from '@octane/spark'


export const InputToolTip = ({
    tooltipText,
    tooltipMsg,
}) => {
    return (
        <ThemeProvider theme={theme}>
            <div
            style={{
                color: `${theme.colors.monochrome.graphite}`,
                fontSize: "0.95em",
                display: "flex",
                zIndex: "12",
                justifyContent: "right",
            }}
            >
            <ToolTip
                id={`${tooltipText}__infoToolTip`}
                showOnHover
                trigger={
                    <>
                        <span>{tooltipText}</span>{' '}
                        <Icon id="tooltips" icon="fmInformation" height={15} width={15} />
                    </>
                }
                placement="right"
                text={tooltipMsg}
            >
                {false}
            </ToolTip>
            </div>
        </ThemeProvider>
    )
}
