import React from "react";
import PropTypes from "prop-types";

import { SliderRange } from "../../../components/slider-range";
import styles from "../vehicle-self-selection.module.scss";
import copy from "../copy.json";

export const Price = ({ value, minValue, maxValue, disabled, onChange, hideHelpText }) => {
    const formatPricing = (rawValue) => {
        const numberFormatter = Intl.NumberFormat("en-US");
        const formatted = numberFormatter.format(rawValue);
        return `$${formatted}`;
    };

    return (
        <>
            <SliderRange
                id="vss-price"
                label={copy.fields.price.label}
                value={value}
                minValue={minValue}
                maxValue={maxValue}
                formatValueLabel={formatPricing}
                disabled={disabled}
                onChange={onChange}
                withPriceField
            />
            {(!(hideHelpText ?? false) && disabled) && (
                <span className={styles.helpText}>
                    {copy.fields.price.helpText}
                </span>
            )}
        </>
    );
};

Price.propTypes = {
    value: PropTypes.number,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    hideHelpText: PropTypes.bool,
};
