import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { OptionGroup, TextLink } from '@octane/spark';
import styles from '../dealer-selector.module.scss';
import copy from '../copy.json';

export const DealerList = ({ options, dealerSelected, onSelectDealer }) => {
    const [showMoreEnabled, setShowMoreEnabled] = useState(false);

    const results = options.length;
    const dealersFoundText = `We found ${results} dealer${
        results > 1 ? 's' : ''
    }`;

    const handleChange = (value) => {
        onSelectDealer && onSelectDealer(value);
    };

    const handleShowMore = () => {
        setShowMoreEnabled(true);
    };

    const list = showMoreEnabled ? options : options.slice(0, 5);

    return (
        <div className={styles.results}>
            <span className={styles.resultsTitle}>{dealersFoundText}</span>

            <OptionGroup onChange={handleChange} value={dealerSelected}>
                {list.map((dealer) => (
                    <OptionGroup.Item key={dealer.id} value={dealer.id}>
                        <span className={styles.dealerName}>{dealer.name}</span>
                        <span className={styles.dealerData}>
                            {dealer.phone}
                        </span>
                        <span className={styles.dealerData}>
                            {dealer.address}
                        </span>
                    </OptionGroup.Item>
                ))}
            </OptionGroup>

            {!showMoreEnabled && options.length > 5 && (
                <div className={styles.action} onClick={handleShowMore}>
                    <TextLink variant="simple">{copy.action.showMore}</TextLink>
                </div>
            )}
        </div>
    );
};

DealerList.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            phone: PropTypes.string.isRequired,
            address: PropTypes.string.isRequired,
        })
    ),
    dealerSelected: PropTypes.string,
    onSelectDealer: PropTypes.func,
};
