const OFFERS_DONE_STATUSES = ['done', 'error'];

/**
 * Checks the response and returns true if loan decision is complete
 * @param {object} response - The response from fetching offers.
 *  {
 *      loan: {status: [any of the values in OFFERS_DONE_STATUSES]}
 *  }
 * @returns {boolean}
 */
export function decisioningIsComplete(response, submittedRCData = false) {
    if (response.loan.result === "manual_review" && submittedRCData) {
        return false;
    }
    return OFFERS_DONE_STATUSES.includes(response.loan.status);
}

/**
 * Repeatedly checks (every 5 seconds) the status of the application until
 * decisioning is complete (or an error occurs) and updates this.offers
 *
 * @param {string} callbackUrl - returned from application submission post
 * @param {ApiStore} apiStore - an instance of the apiStore
 * @returns {Promise} which resolves to the offers (and includes an error flag).
 */
export function pollOffers(callbackUrl, apiStore, submittedRCData = false) {

    // We will continue to poll for offers for up to 5 minutes if necessary (a maximum of 15 attempts, each 6 seconds apart)
    const waitDuration = 6000
    const maximumNumberOfAttempts = 50

    const responseOrStatusIsInvalid = (status, response) => {
        return (status >= 400 || !response || !response.loan);
    };

    // this is the helper that we pass to pollUrl so it can determine whether or not it should keep polling.
    let attempt = 1;
    const shouldContinuePolling = (status, response) => {
        if (responseOrStatusIsInvalid(status, response)) {
            return false;
        }
        if (decisioningIsComplete(response, submittedRCData)) {
            return false;
        }
        if (attempt >= maximumNumberOfAttempts) {
            console.error(
                `Failed to get decision for ${callbackUrl} after ${attempt} attempts`
            );
            return false;
        }

        attempt = attempt + 1;
        return true;
    };

    return apiStore.pollUrl(callbackUrl, shouldContinuePolling, waitDuration)
        .then(({status, response}) => {
            if (responseOrStatusIsInvalid(status, response) || !decisioningIsComplete(response)) {
                return {error: true};
            }

            return {...response, error: false};
        });
}
