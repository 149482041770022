import React from "react";

import PropTypes from "prop-types";
import { Container } from "react-bootstrap";

import { CTAFooter } from "../../components/cta-footer";

import TypeSelector from "./type-selector";
import {
   Price,
   Make,
   Model,
   Condition,
   YearsForNew,
   YearsForUsed,
   MarineEnginesNumber,
   MarineTrailer,
   MarineModelWriteIn,
} from "./fields";
import styles from "./vehicle-self-selection.module.scss";
import copy from "./copy";

export const VehicleSelfSelection = ({ store, isFooterSticky }) => {
   const { type, condition, year, make, model, price, priceSliderOpen, vehicleTypes, isMarineVehicleType } = store;

   const handleTypeSelection = (typeSelected) => {
      store.updateType(typeSelected);
      store.loadMakes();
   };

   const handleMakeSelection = (makeSelected) => {
      store.updateField("make", makeSelected);

      if (makeSelected.id === "none") {
        if (isMarineVehicleType) {
            store.updateField("model", '');
            store.updateField("engines", null);
            store.updateField("has_trailer", null);
        } else {
            store.updateField(
                "model", 
                { id: "none", name: "none" }
            );
        }
        return;
      }
      if (isMarineVehicleType) return;

      store.loadModels();
   };

   const handleSelectYear = (year) => {
      store.updateField("year", year);
      store.loadMakes();
   };

   const handleCondition = (newCondition) => {
      store.updateField("condition", newCondition);
      // eslint-disable-next-line default-case
      switch (newCondition) {
         case "N":
            store.updateField("year", `${store.YEAR_FROM_NEW}`);
            break;
         case "U":
            store.updateField("year", `${store.YEAR_FROM_USED}`);
            break;
      }

      store.loadMakes();
   };

   const handleGoToPrequalForm = () => {
      let url = "/apply";
      const vssQueryParams = store.buildVssQueryParams();
      const currentQueryParams = window.location.search;

      if (currentQueryParams) {
         url += `${currentQueryParams}&${vssQueryParams}`;
      } else {
         url += `?${vssQueryParams}`;
      }

      // redirect to prequal form
      store.historyStore.history.push(url);
   };

  
   const isModelDisabled = !type.id || !condition || !year || !make.id || make.id === "none";
   const isMarineEnginesDisabled = isModelDisabled || !model || model.name === 'none' || !model.name.trim();
   const isMarineHasTrailerDisabled = isModelDisabled || store.marineData.engines === null;
   const isMarinePriceDisabled = isMarineHasTrailerDisabled || store.marineData.hasTrailer === null;
   const isDisabledPrice = !type.id || (model.id !== "none" && model.id !== null);

   return (
      <Container data-oid="vehicle-self-selection-page">
         <form id="vss-form" name="vss-form">
            <div className={styles.container}>
               <div className={styles.header}>
                  <span className={styles.title}>{copy.header.title}</span>
                  <span className={styles.subtitle}>{copy.header.subtitle}</span>
               </div>

               <TypeSelector
                  vehicleTypes={vehicleTypes}
                  selected={type}
                  onChange={handleTypeSelection}
               />

               <div className={styles.formFields}>
                  <Condition value={condition} onChange={handleCondition} disabled={!type.id} />

                  {condition === "N" && (
                     <YearsForNew
                        value={year}
                        disabled={!type.id || !condition}
                        onChange={handleSelectYear}
                     />
                  )}

                  {condition === "U" && (
                     <YearsForUsed
                        value={year}
                        fromYear={store.YEAR_FROM_USED}
                        numberYears={store.YEARS_TO_SHOW}
                        onChange={handleSelectYear}
                     />
                  )}

                  <Make
                     value={make.id}
                     options={store.makesOptions}
                     onChange={handleMakeSelection}
                     disabled={!year || make.preselected}
                     hideNoneOption={isMarineVehicleType}
                  />

                  {isMarineVehicleType ? (
                     <>
                        <MarineModelWriteIn
                           value={model.name}
                           disabled={isModelDisabled}
                           onChange={(modelSelected) => store.updateModelSelected(modelSelected)}
                        />

                        <MarineEnginesNumber 
                           value={store.marineData.engines} 
                           disabled={isMarineEnginesDisabled} 
                           onChange={(engines) => store.updateMarineData('engines', engines)}
                        />

                        <MarineTrailer
                           value={store.marineData.hasTrailer}
                           onChange={(hasTrailer) =>
                              store.updateMarineData('hasTrailer', hasTrailer === true)
                           }
                           disabled={isMarineHasTrailerDisabled}
                        />
                     </>
                  ) : (
                     <Model
                        value={model.id}
                        make={make.id}
                        options={store.modelsOptions}
                        disabled={!type.id || !condition || !year || !make.id || make.id === "none"}
                        onChange={(modelSelected) => store.updateModelSelected(modelSelected)}
                     />
                  )}

                  <Price
                     value={price}
                     minValue={store.PRICE_MIN}
                     maxValue={store.PRICE_MAX}
                     disabled={isMarineVehicleType ? isMarinePriceDisabled : isDisabledPrice && !priceSliderOpen}
                     onChange={(price) => store.updateField("price", price)}
                     hideHelpText={isMarineVehicleType}
                  />
               </div>
            </div>
         </form>

         <CTAFooter
            isSticky={!isFooterSticky}
            primary={{
               oid: "cta-vehicle-self-selection",
               isDisabled: !store.canContinue,
               label: copy.action.label,
               onClick: handleGoToPrequalForm,
            }}
         />
      </Container>
   );
};

VehicleSelfSelection.propTypes = {
    store: PropTypes.shape({
      condition: PropTypes.string,
      year: PropTypes.number,
      make: PropTypes.PropTypes.shape({
         id: PropTypes.string,
         name: PropTypes.string,
      }),
      model: PropTypes.oneOfType([
         PropTypes.string,
         PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
         }),
      ]),
      type: PropTypes.shape({
         id: PropTypes.string,
         name: PropTypes.string,
      }),
      price: PropTypes.number,
      priceSliderOpen: PropTypes.bool,
      vehicleTypes: PropTypes.arrayOf(
         PropTypes.shape({
            id: PropTypes.string,
            icon: PropTypes.string,
            name: PropTypes.string,
         })
      ),
      isMarineVehicleType: PropTypes.bool,
      marineData: PropTypes.shape({
         hasTrailer: PropTypes.bool,
         engines: PropTypes.number,
      }),
      updateField: PropTypes.func,
      updateMarineData: PropTypes.func,
      updateModelSelected: PropTypes.func,
      YEAR_FROM_NEW: PropTypes.number,
      YEAR_FROM_USED: PropTypes.number,
    }),
    isFooterSticky: PropTypes.bool,
};