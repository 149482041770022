import React from "react";
import classNames from "classnames";
import {getItemMatchingValue, getIdValue, getDisplayValue} from "./utils";
import {ThemeProvider, theme, Select} from "@octane/spark";
import styles from "./dropdown.module.scss";

/**
 * Controlled form Dropdown.
 *
 *
 * @param {object}                          props
 * @param {Array<string | number | object>} [props.items]
 * @param {string | number | object}        props.value
 * @param {string | number | object}        [props.selected]    - [Deprecated] Do not use, from original ListDropdown,
 *                                                              used if there isn't a props.value
 * @param {function}                        props.onChange
 * @param {string | number}                 [props.label]
 * @param {string | number}                 [props.id]
 * @param {string | number}                 [props.controlId]   - [Deprecated] Do not use, from original ListDropdown,
 *                                                              used if there isn't a props.id
 * @param {string}                          [props.className]
 * @param {boolean}                         [props.disabled]
 * @param {boolean}                         [props.error]       - There aren't error messages in the spark component
 * @param {string | number}                 [props.placeholder] - Defaults to "", @octane/spark defaults to "Select One"
 *                                                              which doesn't match how we use the dropdown
 * @returns Dropdown component
 */
export const Dropdown = ({
    items = [],
    value,
    selected, // [Deprecated]
    onChange,
    label,
    id,
    controlId, // [Deprecated]
    className,
    disabled,
    error,
    placeholder = "",
    ...props
}) => {
    /* Existing uses of the original dropdown use `selected`.
    To match the api of the other form fields and the component library,
    use `value`, but set it to `selected` if not provided. */
    value = value || selected;

    /* This is casting to string because Select `value` prop type is string */
    const cleanedValue = getIdValue(value);
    value = cleanedValue ? `${cleanedValue}` : null;

    /**
     * Clean the new value returned from the @octane/spark Select
     * on change and call the `onChange` handler if it exists.
     *
     * Note: @octane/spark Select expects and returns strings as
     * the `value`, so newValue should always be a string.
     * @param {string} newValue
     */
    const handleChange = (newValue) => {
        /* Existing uses of the original dropdown expect the selected
        object returned in the onChange instead of just the value
        when a list of objects is passed in as the items */
        onChange && onChange(getItemMatchingValue(items, newValue));
    };

    return (
        <div
            className={classNames("dropdown_list-dropdown mb-3", className, {
                [styles["error"]]: error,
            })}
        >
            <ThemeProvider theme={theme}>
                <Select
                    value={value}
                    onChange={handleChange}
                    id={id || controlId}
                    label={label}
                    placeholder={placeholder}
                    error={!!error}
                    disabled={disabled}
                    {...props}
                >
                    {items.length > 0 ? (
                        items.map((item) => {
                            const itemValue = getIdValue(item);
                            const itemLabel = getDisplayValue(item);

                            // Only render options that have truthy values and labels
                            return itemValue && itemLabel ? (
                                <Select.Option
                                    // Casting to string, as Select expects string comparison for value
                                    value={`${itemValue}`}
                                    key={itemValue}
                                >
                                    {itemLabel}
                                </Select.Option>
                            ) : null;
                        })
                    ) : (
                        <BootstrapSpinner />
                    )}
                </Select>
            </ThemeProvider>
        </div>
    );
};

const BootstrapSpinner = () => (
    <div className={styles.spinnerContainer}>
        <div className="spinner-border spark-text-secondary">
            <span className="sr-only">Loading...</span>
        </div>
    </div>
);
