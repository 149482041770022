import React from "react";
import {observer, inject} from "mobx-react";
import {Route, Switch} from 'react-router-dom';
import {Container} from "react-bootstrap";

import {ServerError} from "../../components/interstitials";
import {withRideOctaneTitle, PageTitles} from "../../pages/octane-title";
import {startWidgetMessageListener} from '../utils/widget';
import {LongPrequalFlowStore} from "./long-prequal-flow-store";
import {LongPrequalStep} from "../steps/long-prequal-step";
import {Offers} from "../../prequal/offers";
import {ReturnCustomer} from "../../prequal/return-customer";
import {DealerSelector} from '../../prequal/dealer-selector';
import {ReturnToServer} from "../../components/return-to-server";

export const LongPrequalFlow = inject(
        'rideOctaneStore',
        'apiStore',
        'userStore',
        'themeStore',
        'partnerStore',
        'historyStore',
        'dealershipStore',
        'uxCopyStore'
    )(
    observer(
        class _LongPrequalFlow extends React.Component {
            store = new LongPrequalFlowStore(this.props);

            componentDidMount() {
                if (this.store.isPartnerDealerWidgetExperience && !this.store.isVehicleSelfSelectionExperience) {
                    const stores = {
                        partnerStore: this.store.partnerStore,
                        dealershipStore: this.store.dealershipStore,
                        historyStore: this.store.historyStore,
                    }
                    const onWidgetTimeout = () => this.store.showServerErrorView("Widget Error.");
                    const onWidgetSuccess = () => this.store.initializeVehicleConfiguration();

                    startWidgetMessageListener(window, stores, onWidgetTimeout, onWidgetSuccess);
                } else {
                    this.store.initializeVehicleConfiguration();
                }
            }

            render() {
                if (this.store.showServerError) {
                    return (
                    <Container data-oid="long-prequal-flow-error-page">
                        <ServerError
                            origin="long-prequal-flow"
                            errorMessage={this.store.serverErrorMessage}
                        />
                    </Container>
                    );
                }

                const longPrequalTitle = this.store.isCoapplicantFlow ?
                                    PageTitles.LONG_PREQUAL_COAPPLICANT_TITLE :
                                    PageTitles.LONG_PREQUAL_TITLE;
                const titleWords = this.store.displayVehicle ? [this.store.displayVehicle.name] : [];

                return (
                    <div>
                        <Switch>
                            <Route exact path={["/:slug?/apply", "/:slug?/apply/coapplicant"]}>
                                {withRideOctaneTitle(
                                    <LongPrequalStep
                                        match={this.props.match}
                                        hasFetchedVehicle={this.store.hasFetchedVehicle}
                                        vehicleConfiguration={this.store.displayVehicle}
                                        isDummyVehicleEnabled={this.store.isDummyVehicleEnabled}
                                        rawVehicleData={this.store.rawVehicleData}
                                        isCoapplicantFlow={this.store.isCoapplicantFlow}
                                        flowStore={this.store}
                                    />, [...titleWords, longPrequalTitle]
                                )}
                            </Route>
                            <Route exact path={"/:slug?/apply/dealer/:appUuid"}>
                                {withRideOctaneTitle(
                                    <DealerSelector
                                        zipCodeUser={this.props.userStore.userInfo['zip_code']}
                                        onSubmitDealership={this.store.onSubmitDealership}
                                    />,
                                    [...titleWords, PageTitles.DEALER_SELECTOR]
                                )}
                            </Route>
                            <Route exact path={["/:slug?/apply/offers/:appUuid", "/:slug?/apply/offers/:appUuid/:applicantUuid"]}>
                                {withRideOctaneTitle(
                                    <Offers
                                        applicationUuid={this.store.applicationUuid}
                                        applicantUuid={this.store.primaryApplicantUuid}
                                        hasCompletedAboutYouForm={this.store.hasCompletedLongPrequalForm}
                                        hasSubmittedSSN={this.store.hasCompletedLongPrequalForm}
                                        aboutYouPrequalUrl={this.store.getPrequalUrl()}
                                        returnCustomerUrl={this.store.getPrequalUrl(`/return-customer/${this.store.applicationUuid}`)}
                                        ssnRequiredUrl={null}
                                        isApplicantCMPFlow={!!this.store.primaryApplicantUuid}
                                        accessoriesAmount={this.store.accessoriesAmount}
                                        vehicleConfiguration={this.store.displayVehicle}
                                        offersEndpoint={`/api/v2/applications/${this.store.applicationUuid}/`}
                                        hasSubmittedRCData={this.store.hasSubmittedRCData}
                                    />,
                                    [...titleWords, PageTitles.OFFERS_TITLE]
                                )}
                            </Route>
                            <Route exact path={["/:slug?/apply/return-customer/:appUuid"]}>
                                {withRideOctaneTitle(
                                    <ReturnCustomer
                                        applicationUuid={this.store.applicationUuid}
                                        onSubmitRCData={this.store.onSubmitRCData}
                                    />,
                                    [...titleWords, PageTitles.RETURN_CUSTOMER_TITLE]
                                )}
                            </Route>
                            <Route component={ReturnToServer} />
                        </Switch>
                    </div>
                );
            }
        }
    )
);
