import Ajv from 'ajv';

import { validatePayload, raw_vehicle_data_schema, vehicle_payload_schema } from '../../utils/validation';
import { getRideAPIBaseUrl } from './urls';

/*
* Validates the vehicle payload received from the dealer cta widget
* Returns true if the payload is correctly formatted otherwise returns false
*/
export const validateVehiclePayload = (data)=>{
    const ajv = new Ajv();
    const validationResult = ajv.validate(vehicle_payload_schema, data);
    console.debug({"Validation Result": validationResult, errors: ajv.errors});
    return validationResult;
}

/*
* Get raw vehicle data received from query params
* Returns object if the data is correctly otherwise returns false
*/
export const parseRawVehicleDataFromQueryParams = (queryParams)=>{
    const data = {
        raw_type: queryParams.rawType,
        raw_condition: queryParams.rawCondition,
        raw_make: queryParams.rawMake || null,
        raw_model: queryParams.rawModel || null,
        raw_year: queryParams.rawYear,
        raw_price: queryParams.rawPrice,
        raw_subcategory: queryParams.rawSubcategory || null,
        raw_engines: queryParams.rawEngines || null,
        raw_has_trailer: queryParams.rawHasTrailer || null,
    }

    const isValid = validatePayload(raw_vehicle_data_schema, data)
    if (!isValid) {
        console.error({msg:`VSS validation payload from query params fail:[${isValid}]`, queryParams});
        return null;
    }

    const rawVehicleData = {
        ...data,
        raw_name: buildRawNameForVss(data),
        raw_msrp: data.raw_price,
        raw_year: parseInt(data.raw_year),
        raw_price: parseInt(data.raw_price),
        raw_engines: data.raw_engines ? parseInt(data.raw_engines) : null,
        raw_has_trailer: data.raw_has_trailer === 'true',
    }

    return rawVehicleData
}

const buildRawNameForVss = (data) => {
    if(data.raw_make ==='-') return data.raw_type
    return `${data.raw_make} ${data.raw_model || ''}`;
}

export async function fetchVehicleConfigFromUuid(vehicleConfigurationUuid, partnerIdentifier=null) {
    let endpoint = `configurations/${vehicleConfigurationUuid}`;
    if (!!partnerIdentifier) {
        endpoint = `partners/${partnerIdentifier}/${endpoint}`; 
    }
    endpoint = getRideAPIBaseUrl() + '/api/v1/' + endpoint
    const {success, status, data} = await fetchVehicleConfiguration(endpoint);
    if (!success) {
        throw new Error(`Failed to fetch vehicle configuration for uuid: ${vehicleConfigurationUuid}. Status: ${status}, Response: ${JSON.stringify(data)}`);
    }
    return data;
};

export async function fetchVehicleConfigFromSlug(vehicleConfigurationSlug, partnerIdentifier=null) {
    let endpoint = `configurations/${vehicleConfigurationSlug}`;
    if (!!partnerIdentifier) {
        endpoint = `partners/${partnerIdentifier}/${endpoint}`; 
    }
    endpoint = getRideAPIBaseUrl() + '/api/v1/' + endpoint
    const {success, status, data} = await fetchVehicleConfiguration(endpoint);
    if (!success) {
        throw new Error(`Failed to fetch vehicle configuration for slug: ${vehicleConfigurationSlug}. Status: ${status}, Response: ${JSON.stringify(data)}`);
    }
    return data;
};

export async function fetchVehicleConfigFromVehicleIdentifier(vehicleIdentifier, partnerIdentifier) {
    vehicleIdentifier = vehicleIdentifier.toUpperCase();
    let endpoint = `partners/${partnerIdentifier}/configurations/${vehicleIdentifier}`;
    endpoint = getRideAPIBaseUrl() + '/api/v1/' + endpoint
    const {success, status, data} = await fetchVehicleConfiguration(endpoint);
    if (!success) {
        throw new Error(`Failed to fetch vehicle configuration for identifier: ${vehicleIdentifier} (partner: ${partnerIdentifier}). Status: ${status}, Response: ${JSON.stringify(data)}`);
    }
    return data;
};

async function fetchVehicleConfiguration(
    fetchUrl,
    fields = ['uuid', 'slug', 'name', 'msrp', 'default_image', 'color']
) {
    try {
        fetchUrl = fetchUrl + '?fields=' + fields.join(',')
        const response = await fetch(fetchUrl, {
            query: { fields },
            method: 'GET',
        });
        const responseData = await response.json();
        if (!response.ok) {
            console.error('Failed to fetch vehicle configuration', { url: fetchUrl, status: response.status, data: responseData });
        }
        return { success: response.ok, status: response.status, data: responseData };
    } catch (error) {
        console.error('Error while fetching vehicle configuration', { error });
        throw error;
    }
};


export const vehicleDisplayData = ({vehicleConfiguration, partnerVehicleMatch}) => {
    if (!vehicleConfiguration && !partnerVehicleMatch) {
        return null;
    }

    // initialize data with values from this.vehicleConfiguration (if it is defined, otherwise set fields to null)
    let displayData = ['uuid', 'slug', 'name', 'msrp', 'default_image', 'color'].reduce((output, key) => {
        output[key] = !!vehicleConfiguration ? vehicleConfiguration[key] : null;
        return output;
    }, {});

    // use partnerVehicleMatch for display fields (if available)
    if (!!partnerVehicleMatch) {
        displayData.name = partnerVehicleMatch.model;
        displayData.msrp = partnerVehicleMatch.price;
        displayData.default_image = null;

        const partnerVehicleMatchColorIsValid = !!partnerVehicleMatch.color && partnerVehicleMatch.color !== '___';
        displayData.color = partnerVehicleMatchColorIsValid ? partnerVehicleMatch.color : null;
    }

    // return null if name or msrp are not set
    if (!displayData.name || !displayData.msrp) {
        return null;
    }
    return displayData;
};