import React from 'react';
import PropTypes from 'prop-types';

import { MaskedTextInput, Button } from '@octane/spark';
import styles from '../dealer-selector.module.scss';

export const ZipCodeSearch = ({
    value,
    isLoading,
    isZipCodeValid,
    onChange,
    onSearch,
}) => {
    const handleChange = (zipCode) => {
        onChange && onChange(zipCode);
    };

    const handleSearch = () => {
        onSearch && onSearch();
    };

    return (
        <div className={styles.zipCodeWrapper}>
            <MaskedTextInput
                id="zipCode"
                type="text"
                value={value}
                placeholder="Zip Code"
                maxLength={5}
                mask={'00000'}
                errorText="Specify a valid zip code"
                onChange={handleChange}
            />
            <Button
                name="search"
                id="btnSearch"
                variant="primary"
                size="tiny"
                onClick={handleSearch}
                disabled={!isZipCodeValid || isLoading}
            >
                Search
            </Button>
        </div>
    );
};

ZipCodeSearch.propTypes = {
    value: PropTypes.string,
    isLoading: PropTypes.bool,
    isZipCodeValid: PropTypes.bool,
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
};
