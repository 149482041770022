import React from "react";
import PropTypes from "prop-types";

import { Tab } from "@octane/spark";
import copy from "../copy.json";

export const MarineTrailer = ({ value, disabled, onChange }) => {

    const handleChange = (value) => {
        onChange(value === 'true');
    };

    return (
        <Tab
            id="tab-marine-has-trailer"
            label={copy.fields.marineTrailer.label}
            initialValue={value === true ? 'true' : 'false'}
            disabled={disabled}
            items={[
                {
                    value: 'true',
                    label: copy.fields.marineTrailer.items.yes,
                },
                {
                    value: 'false',
                    label: copy.fields.marineTrailer.items.no,
                },
            ]}
            onChange={handleChange}
        />
    );
};

MarineTrailer.propTypes = {
    value: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};
